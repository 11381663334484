/* global moment toastr $ flatpickr */

import { request } from "../lib/utils";
import { load_choice } from "../choice/utils";

import React, { Component } from "react";
import { connect } from "react-redux";


class DateInput extends Component {
  constructor(props) {
    super(props);
    this.ref_input = React.createRef();
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    const that = this;
    var input = that.ref_input.current;

    flatpickr(input, {
      dateFormat: "d.m.Y",
      allowInput: true,
      "locale": "ru"
    });
  }

  onChange(e) {
    this.props.onChange(this.props.name, e.target.value)
  }

  render() {
    const props = this.props;

    return (
      <input
        type="text"
        className="form-control"
        name={props.name}
        onChange={this.onChange}
        onInput={this.onChange}
        ref={this.ref_input}
        value={props.value || ""}
        disabled={props.disabled}
      />
    )
  }
}

class DateRange extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this)
  }

  get value() {
    return this.props.value || {}
  }

  onChange(name, value) {
    this.props.onChange(this.props.name, { ...this.value, [name]: value })
  }

  render() {
    const props = this.props

    return (
      <div className="input-group">
        <DateInput
          name="from"
          value={this.value.from || ""}
          disabled={props.disabled}
          onChange={this.onChange}
        />
        <DateInput
          name="to"
          value={this.value.to || ""}
          disabled={props.disabled}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

class DateTimeInput extends Component {
  constructor(props) {
    super(props);
    this.ref_input = React.createRef();
    this.onChange = this.onChange.bind(this)
  }

  // Конвертирует значение между форматом ISO,
  // в котором данные переданы сюда и своим форматом отображения
  formatValue(value, direction) {
    if (!value) {
      return "";
    }

    if (direction === "in")
      return moment(value).format("DD.MM.YYYY HH:mm:ss");

    if (direction === "out")
      return moment(value, "DD.MM.YYYY HH:mm:ss").format();
  }

  onChange(e) {
    // При изменении конвертируем значение в ISO формат для отправки наружу из поля
    this.props.onChange(this.props.name, this.formatValue(e.target.value, "out"))
  }

  componentDidMount() {
    const that = this;
    var input = that.ref_input.current;

    flatpickr(input, {
      enableTime: true,
      dateFormat: "d.m.Y H:i:S",
      allowInput: true,
      locale: "ru"
    });
  }

  render() {
    const props = this.props;

    return (
      <input
        type="text"
        className="form-control"
        name={props.name}
        onChange={this.onChange}
        onInput={this.onChange}
        ref={this.ref_input}
        value={this.formatValue(props.value || "", "in")}
        disabled={props.disabled}
      />
    )
  }
}

class DateTimeRange extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this)
  }

  get value() {
    return this.props.value || {}
  }

  onChange(name, value) {
    this.props.onChange(this.props.name, { ...this.value, [name]: value })
  }

  render() {
    const props = this.props

    return (
      <div className="input-group">
        <DateTimeInput
          name="from"
          value={this.value.from || ""}
          disabled={props.disabled}
          onChange={this.onChange}
        />
        <DateTimeInput
          name="to"
          value={this.value.to || ""}
          disabled={props.disabled}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

class TableInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addRow = this.addRow.bind(this);
  }

  onChange(rowIdx, name, value) {
    let table_value = [...this.props.value];
    table_value[rowIdx] = { ...table_value[rowIdx], [name]: value };
    this.props.onChange(this.props.name, table_value);
  }

  addRow() {
    let table_value = [...(this.props.value || [])];
    table_value.push({ id: 0 });
    this.props.onChange(this.props.name, table_value);
  }

  removeRow(rowIdx) {
    let table_value = [...(this.props.value || [])];
    table_value.splice(rowIdx, 1);
    this.props.onChange(this.props.name, table_value);
  }

  render() {
    let that = this;
    const access = this.props.access;
    const attrs = this.props.inner_attrs;
    const table_value = this.props.value || [];

    // Определяемся с набором колонок, которые мы будем выводить в таблице
    // Определяем их по первой строке. Пока пусть так будет
    let columns = [];
    for (const attr_name in access[0]) {
      const attr = attrs[attr_name];
      if (attr !== undefined) {
        columns.push(attr);
      }
    }

    // Эта функция сделана изолированной, так как может быть переопределена
    // и очень важно, чтобы все нужные для этого параметры могли быть получены через аргументы
    let renderTd;
    if (this.props.renderTd !== undefined) {
      renderTd = this.props.renderTd
    }
    else {
      renderTd = (row_idx, attr, access, row_data, table_component) => {
        const value = row_data[attr.name];

        return (
          <td key={attr.name}>
            <Input
              data_type={attr.data_type}
              name={attr.name}
              inner_attrs={attr.inner_attrs}
              value={value}
              choice_name={attr.choice_name}
              access={access}
              onChange={(name, value) => {
                table_component.onChange(row_idx, name, value);
              }}
            />
          </td>
        );
      }
    }

    function renderRow(row_data, row_idx) {
      const row_access = access[row_data.id];

      return (
        <tr key={row_idx}>
          {columns.map((attr) => {
            const col_access = row_access[attr.name];
            return renderTd(row_idx, attr, col_access, row_data, that);
          })}
          <td>
            {row_access.can_delete && (
              <span
                className="btn btn-icon btn-sm btn-secondary waves-effect waves-light"
                onClick={() => {
                  that.removeRow(row_idx);
                }}
              >
                <span className="ti ti-backspace"></span>
              </span>
            )}
          </td>
        </tr>
      );
    }

    return (
      <div>
        {table_value.length > 0 && (
          <table className="tableinput mb-1">
            <thead>
              <tr>
                {columns.map((attr) => {
                  return (
                    <th key={attr.name} className="text-center">
                      {attr.title}
                    </th>
                  );
                })}
                <th></th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              {table_value.map(renderRow)}
            </tbody>
          </table>
        )}
        {access[0].can_create && (
          <span className="btn btn-secondary waves-effect btn-xs" onClick={this.addRow}>
            <i className="ti ti-code-plus ti-xs me-1"></i> Добавить
          </span>
        )}
      </div>
    );
  }
}


/**
 * tags: boolean
 * multiple: boolean
 * name: str
 * value: str | list[str]
 * access: edit | read
 * choice_items: list[str, str]
 * onChange: callable(str, str | list[str])
 */
class Select2Input extends Component {

  constructor(props) {
    super(props)
    this.ref_input = React.createRef()
  }

  componentDidMount() {
    const that = this
    const ref_input_jq = $(this.ref_input.current)

    // Определяем, поверх чего рисовать выпадающий список, если input внутри модалки
    let dropdownParent = $("body");

    if (ref_input_jq.parents('.modal:first').length !== 0) {
      dropdownParent = ref_input_jq.closest('.modal');
    }

    ref_input_jq.select2({
      tags: this.props.tags,
      dropdownParent: dropdownParent,
    }).on('change', function (event) {
      that.props.onChange(event)
    })
  }

  componentDidUpdate(prevProps) {
    // Если value изменилось, заставляем перерисовываться, на случай,
    // если оно изменилось снаружи
    if (this.props.value !== prevProps.value) {
      $(this.ref_input.current).trigger('change.select2');
    }
  }

  render() {
    if (this.props.choice_items) {
      return (
        <select
          className="form-control"
          multiple={this.props.multiple}
          name={this.props.name}
          defaultValue={this.props.value}
          disabled={this.props.disabled}
          ref={this.ref_input}>
          {this.props.choice_items.map((item) => {
            return (
              <option key={item[0]} value={item[0]}>
                {item[1]}
              </option>
            );
          })}
        </select>
      )
    }

    return <span>Загрузка опций...</span>

  }

}


class SummernoteInput extends Component {

  constructor(props) {
    super(props)
    this.ref_input = React.createRef()
  }

  componentDidMount() {
    const that = this

    $(this.ref_input.current).summernote({
      height: this.props.height || 200,
      toolbar: this.props.toolbar || [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']]
      ]
    }).on('summernote.change', function (we, contents, $editable) {
      that.props.onChange(that.props.name, contents)
    });
  }

  componentWillUnmount() {
    $(this.ref_input.current).summernote('destroy');
  }

  render() {
    return <textarea name={this.props.name}
      placeholder={this.props.placeholder}
      disabled={this.props.disabled}
      onChange={this.props.onChange}
      value={this.props.value || ""}
      ref={this.ref_input} />
  }
}

class InputRaw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choice_items: undefined,
    };

    this.updateChoice = this.updateChoice.bind(this);
    this.onChange = this.onChange.bind(this);
    this.update_choice_in_work = false;
  }

  componentDidUpdate(prevProps) {
    // У полей, которые запрашивают опции себе сами, при изменении контекста, перезапросить опции
    if (
      this.props.choice_name !== undefined &&
      JSON.stringify(this.props.choice_context) !==
      JSON.stringify(prevProps.choice_context)
    ) {
      this.updateChoice();
    }
  }

  getChoiceItems() {
    // Опции переданы в компонент в готовом виде
    if (this.props.choice_items !== undefined) {
      return this.props.choice_items;
    }

    // Опции с контекстом компонент для себя запрашивает сам и хранит в state
    if (this.props.choice_context) {
      return this.state.choice_items;
    }

    // Опции без контекста берем из redux
    else {
      return this.props.choices[this.props.choice_name];
    }
  }

  onChange(event) {
    const data_type = this.props.data_type;
    const name = event.target.name;
    const options = event.target.options;

    let value

    if (data_type === "boolean") {
      value = event.target.checked;
    }
    else {
      value = event.target.value;
    }

    if (["m2m_string", "m2m_integer"].includes(data_type)) {
      value = [];

      let value_item;
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value_item = options[i].value;
          if (data_type === "m2m_integer") {
            value_item = parseInt(value_item);
          }
          value.push(value_item);
        }
      }
    }

    if (this.props.data_type === "integer")
      value = value === "" ? null : parseInt(value);

    this.props.onChange(name, value);
  }

  /**
   * Обновляем
   */
  updateChoice() {
    let that = this;
    const choice_name = this.props.choice_name;

    // Кастомные опции хранятся в state этого компонента
    if (that.props.choice_context) {
      if (this.update_choice_in_work) {
        return;
      }

      this.update_choice_in_work = true;

      request({
        method: "post",
        url: "/api/choice/items",
        data: {
          name: choice_name,
          context: that.props.choice_context,
        },
        success: (data) => {
          that.setState({ choice_items: data }, () => {
            that.update_choice_in_work = false;
          });
        },
        error: (data) => {
          toastr.error(data);
          that.update_choice_in_work = false;
        },
      });
    }

    // Стандартные опции после получения отправляются в redux
    else {
      load_choice(choice_name);
    }
  }

  render() {
    let props = this.props;
    let choice_items = this.getChoiceItems();
    const data_type = props.data_type;
    const access = props.access

    // Если требуются опции, но они пока не пришли
    if (props.choice_name && (choice_items === undefined || choice_items === null)) {
      this.updateChoice();
      return <div>Загружается...</div>;
    }

    if (data_type === "summernote") {
      return <SummernoteInput
        name={props.name}
        value={props.value}
        disabled={access === "read"}
        onChange={props.onChange}
      />
    }

    if (data_type === "date_range") {
      return <DateRange
        name={props.name}
        value={props.value}
        disabled={access === "read"}
        onChange={props.onChange}
      />
    }

    if (data_type === "datetime_range") {
      return <DateTimeRange
        name={props.name}
        value={props.value}
        disabled={access === "read"}
        onChange={props.onChange}
      />
    }

    if (["integer", "string"].includes(data_type) && choice_items) {
      return (
        <select
          className="form-select"
          name={props.name}
          value={props.value || ""}
          disabled={access === "read"}
          onChange={this.onChange}
        >
          <option key="blank" value="">----</option>
          {choice_items.map((item) => {
            return (
              <option key={item[0]} value={item[0]}>{item[1]}</option>
            );
          })}
        </select>
      );
    }

    if (["m2m_string", "m2m_integer"].includes(data_type)) {
      return <Select2Input
        name={props.name}
        value={props.value || []}
        disabled={access === "read"}
        onChange={this.onChange}
        multiple={true}
        choice_items={choice_items}
      />
    }

    if (["string", "integer", "decimal", "float"].includes(data_type)) {
      return (
        <input
          type="text"
          disabled={access === "read"}
          className="form-control"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value || ""}
          onChange={this.onChange}
        />
      );
    }

    if (data_type === "time") {
      return (
        <input
          type="text"
          disabled={access === "read"}
          className="form-control"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value || ""}
          onChange={this.onChange}
        />
      );
    }

    if (data_type === "property") {
      return <div className="form-control">{props.value}</div>
    }

    if (data_type === "text") {
      return (
        <textarea
          type="text"
          rows={props.rows}
          className="form-control"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value || ""}
          disabled={access === "read"}
          onChange={this.onChange}
        />
      );
    }

    if (props.data_type === "datetime") {
      return (
        <DateTimeInput
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          disabled={access === "read"}
          onChange={props.onChange}
        />
      );
    }

    if (props.data_type === "date") {
      return (
        <DateInput
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          disabled={access === "read"}
          onChange={props.onChange}
        />
      );
    }

    if (props.data_type === "password") {
      return (
        <input
          type="password"
          className="form-control"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value || ""}
          disabled={access === "read"}
          onChange={this.onChange}
        />
      );
    }

    if (props.data_type === "boolean") {
      return (
        <div className="form-check form-switch mb-2 pt-2" key={props.name}>
          <input
            className="form-check-input"
            type="checkbox"
            name={props.name}
            checked={props.value || false}
            disabled={access === "read"}
            onChange={this.onChange}
          />
        </div>
      )
    }

    if (props.data_type === "table" && props.inner_attrs) {
      return <TableInput {...props} />;
    }

    return <div>{props.data_type}</div>;
  }
}

export let Input = connect((state) => {
  return { choices: state.choice.choices };
})(InputRaw);

/**
 * Универсальный элемент формы ввода, который отображается по разному,
 * в зависимости от типа данных вместе с названием поля (label)
 * и принимает параметры:
 * - data_type (
 *      bool,
 *      integer, float, decimal,
 *      string, password, text, html,
 *      date, datetime, date_range,
 *      property, m2m_string, m2m_integer, table,
 *  )
 * - name
 * - inner_attrs
 * - title
 * - value
 * - choice_name
 * - choice_context
 * - choice_items
 * - messages
 * - access
 * - onChange
 * - placeholder
 */
export default class AttrInput extends Component {
  renderMessages() {
    if (this.props.messages) {
      return this.props.messages.map((message) => {
        return <div className={message[1]}>{message[0]}</div>
      })
    }
    return null
  }

  render() {
    const props = this.props;

    if (props.horizontal) {
      return (
        <div className="row mb-1">
          {props.title && (
            <label className="col-md-6 col-form-label">{props.title}</label>
          )} {" "}
          <div className="col-md-6">
            <Input {...props} />
            {this.renderMessages()}
          </div>
        </div>
      )
    }

    return (
      <div className="form-group">
        {props.title && (
          <label className="form-label">{props.title}</label>
        )} {" "}
        <Input {...props} />
        {this.renderMessages()}
      </div>
    );
  }
}
