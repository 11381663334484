import { connect } from 'react-redux'
import { BaseModelView } from '../smart_bps/bps/model_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'

class ExpenseDocumentView extends BaseModelView {
  renderEventBlock() {}

  renderContentLeftAction() {
    // Получим список категорий и разделим их на категории поступления и списания
    let category_in = [];
    let category_out = [];
    const categorys = this.getChoices("ExpenseCategory")
    if(categorys) {
      categorys.forEach(item => {
        if(item[0].startsWith("in_")) {
          category_in.push(item)
        }
        if(item[0].startsWith("out_")) {
          category_out.push(item)
        }
      })
    }

    return (
      <div className="card mb-3" key="actions">
        <div className="card-body p-2">
          <span className="btn btn-outline-primary waves-effect p-2 me-2" onClick={this.saveObject}>
            <span className="ti ti-xs ti-check me-1"></span>
            Сохранить
          </span>
          {this.state.perms.includes("delete") && <button className="btn btn-outline-danger waves-effect p-2 me-2" onClick={this.deleteObject}>
            <span className="ti ti-xs ti-trash me-1"></span>
            Удалить</button>}
        </div>
      </div>
    )
  }

  renderContent() {
    return <div className="row" key="content">
      <div className="col-lg-8">
        {this.renderContentLeft()}
      </div>
    </div>
  }

}

export default wrapView(ExpenseDocumentView, (state) => {
    return stateToProps(state, 'expense')
})
