import { Link } from 'react-router-dom'
import { BaseListView } from '../smart_bps/bps/list_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'
import { Paginator } from '../smart_bps/lib/components'
import { request } from '../smart_bps/lib/utils'


class ExpenseListView extends BaseListView {

  constructor(props) {
    super(props)

    this.state = {
      accounts: undefined
    }
  }

  waitRenderReady() {
    let that = this

    const dummyContent = super.waitRenderReady()
    if (dummyContent !== undefined) {
      return dummyContent
    }

    // Мета-данные проекта не пришли
    if (!this.state.accounts) {
      that.waitRenderReadyLock = true
      request({
        method: 'get',
        url: `/api/expense/accounts`,
        success: (data) => {
          that.waitRenderReadyLock = false
          that.setState({ accounts: data })
        },
      })
      return <div>Загрузка данных по счетам...</div>
    }
  }

  renderContent() {
    let view = this

    function renderDocument(item) {
      const direction_icon = item.direction === "Расход" ? "badge badge-dot bg-dark me-1" : "badge badge-dot bg-success me-1"

      return <div className="card mb-2" key={item.id}>
        <div className="card-body p-2">
          <span className="me-2">{item.d_action}</span>
          <span className="me-2">
            <span class={direction_icon}></span>
            {item.category}
            {item.budget_id && (
              <span className="mx-1">({item.budget_id})</span>
            )}
          </span>
          <span className="me-2">
            <Link to={`/${view.project_name}/${item.id}/`}>{Math.round(item.value / 1000)} тыс. руб.</Link>
          </span>
          <span className="me-2">{item.title}</span>
        </div>
      </div>
    }

    if (this.props.list_items.length === 0) {
      return <div className="card mb-3">
        <div className="card-body p-3">
          Данные не найдены. Уточните условия отбора и поиска.
        </div>
      </div>
    }

    return [
      this.state.accounts.map((account) => {
        return <div className="card mb-2" key={account[0]}>
          <div className="card-body p-2"><span className="me-3">
            <span className="me-2">{account[0]}</span>
            {account[1]} тыс. руб.
          </span>
          </div>
        </div>
      }),

      this.props.list_items.map(renderDocument),

      <Paginator current_page={this.props.list_page}
        count_pages={this.props.list_count_pages}
        has_next={this.props.list_has_next_page}
        setPage={this.updateItems} />
    ]
  }
}

export default wrapView(ExpenseListView, (state) => {
  return stateToProps(state, 'expense')
})
