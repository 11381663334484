import React from 'react'

import { BaseView, baseStateToProps } from './smart_bps/lib/base_view'
import { wrapView } from './smart_bps/lib/utils'
import { Navigate } from 'react-router-dom'


class IndexView extends BaseView {
  constructor(props) {
    super(props)
    this.auth_required = false
  }

  renderContent() {
    if (this.props.user) {
      return <Navigate to="/expense" replace={true} />
    }
    return <Navigate to="/auth/login" replace={true} />
  }
}

export default wrapView(IndexView, baseStateToProps)
